import Vue from 'vue'

export default Vue.extend({
  name: 'CustomersTableMixin',
  data() {
    return {
      isLoading: false,
      sort: [
        {
          'firstName.keyword': {
            order: 'asc',
          },
        },
        {
          'lastName.keyword': {
            order: 'asc',
          },
        },
      ],
    }
  },
})


































import Vue, { PropType } from 'vue'
import { DiscountFormInput, DiscountType } from '../../../../shared/types/types'

export default Vue.extend({
  name: 'DiscountFormTypes',
  props: {
    form: {
      type: Object as PropType<DiscountFormInput>,
      required: true,
    },
  },
  computed: {
    discount(): DiscountFormInput {
      return this.form
    },
    shouldShowFreeShipping(): boolean {
      return this.discount.type === DiscountType.Code
    },
  },
})

import gql from 'graphql-tag'

/**
 * Mutation to delete a discount for the specified discountId
 */
export const DELETE_DISCOUNT = gql`
  mutation deleteDiscount($discountId: ID!) {
    deleteDiscount(discountId: $discountId) {
      successful
    }
  }
`

/**
 * Bulk Mutation to delete discounts
 */
export const DELETE_DISCOUNTS = gql`
  mutation deleteDiscounts($discountIds: [ID]!) {
    deleteDiscounts(discountIds: $discountIds) {
      successful
    }
  }
`

/**
 * Mutation to update Discount
 */
export const UPDATE_DISCOUNT = gql`
  mutation updateDiscount($discountId: ID!, $form: DiscountForm!) {
    updateDiscount(discountId: $discountId, form: $form) {
      name
      type
      status
      usage
      currency
      summary
      rules {
        type
        value
        startsAt
        endsAt
        usageLimit
        usageOncePerUser
        prerequisiteCustomerIds
        prerequisiteMinPurchaseAmount {
          amount
          currency
        }
        prerequisiteMinQuantity
        entitledCountryCodes
        entitledProductVariantIds
        prerequisiteProductVariantIds
        prerequisiteToEntitlement {
          entitledQuantity
          prerequisiteQuantity
          prerequisitePurchaseAmount {
            amount
            currency
          }
          maxNumberOfUses
        }
      }
    }
  }
`

/**
 * Mutation to create Discount
 */
export const CREATE_DISCOUNT = gql`
  mutation createDiscount($form: DiscountForm!) {
    createDiscount(form: $form) {
      name
      type
      status
      usage
      currency
      summary
      rules {
        type
        value
        startsAt
        endsAt
        usageLimit
        usageOncePerUser
        prerequisiteCustomerIds
        prerequisiteMinPurchaseAmount {
          amount
          currency
        }
        prerequisiteMinQuantity
        entitledCountryCodes
        entitledProductVariantIds
        prerequisiteProductVariantIds
        prerequisiteToEntitlement {
          entitledQuantity
          prerequisiteQuantity
          prerequisitePurchaseAmount {
            amount
            currency
          }
          maxNumberOfUses
        }
      }
    }
  }
`

/**
 * Bulk mutation to enable Discounts
 */
export const ENABLE_DISCOUNTS = gql`
  mutation enableDiscounts($discountIds: [ID]!) {
    enableDiscounts(discountIds: $discountIds) {
      successful
    }
  }
`

/**
 * Bulk mutation to disable Discounts
 */
export const DISABLE_DISCOUNTS = gql`
  mutation disableDiscounts($discountIds: [ID]!) {
    disableDiscounts(discountIds: $discountIds) {
      successful
    }
  }
`

export const SYNC_DISCOUNT_CURRENCIES = gql`
  mutation syncDiscountCurrencies($discountId: ID!) {
    syncDiscountCurrencies(discountId: $discountId) {
      name
    }
  }
`

export const UPDATE_DISCOUNT_CURRENCIES = gql`
  mutation UpdateDiscountCurrencies(
    $discountId: ID!
    $input: [DiscountFormInput!]!
  ) {
    updateDiscountCurrencies(discountId: $discountId, input: $input) {
      currencies {
        name
        type
        status
        usage
        currency
        summary
        rules {
          type
          value {
            ... on DiscountPriceValue {
              amount
              currency
              type
            }
            ... on DiscountScalarValue {
              value
            }
          }
          startsAt
          endsAt
          usageLimit
          usageOncePerUser
          prerequisiteCustomerIds
          prerequisiteMinPurchaseAmount {
            amount
            currency
          }
          prerequisiteMinQuantity
          entitledCountryCodes
          entitledProductVariantIds {
            productId
            variantId
          }
          prerequisiteProductVariantIds {
            productId
            variantId
          }
          prerequisiteToEntitlement {
            entitledQuantity
            prerequisiteQuantity
            prerequisitePurchaseAmount {
              amount
              currency
            }
            maxNumberOfUses
          }
        }
      }
    }
  }
`

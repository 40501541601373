<template>
  <div id="input-container" class="mr-20">
    <b-input
      v-model="formattedPercentageValue"
      placeholder="0 %"
      min="0"
      max="100"
      @blur="focusOut"
      @focus="focusIn"
    />
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'PercentageInput',
  props: {
    value: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      percentageValue: this.value !== undefined ? this.value : 0,
      formattedPercentageValue:
        this.value !== undefined ? this.value + ' %' : 0 + ' %',
    }
  },
  methods: {
    focusOut() {
      const value = Math.min(
        parseFloat(this.formattedPercentageValue.replace(/[^\d\.]/g, '')),
        100
      )
      this.percentageValue = value

      this.formattedPercentageValue =
        this.percentageValue
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') + ' %'
      this.$emit('input', this.percentageValue)
    },
    focusIn() {
      this.formattedPercentageValue = this.percentageValue.toString()
    },
  },
})
</script>






























































enum AppliesToEnum {
  Everyone = 'everyone',
  Customers = 'customers',
}

import Vue, { PropType } from 'vue'
import CustomersModal from '@/components/shared/CustomersModal.vue'
import Loader from '@/components/shared/Loader.vue'
import { DiscountFormInput, Customer } from '../../../../shared/types/types'
import Separator from '../shared/Separator.vue'
import { getShortCustomerById } from '@/lib/user'

export default Vue.extend({
  name: 'DiscountFormCustomers',
  components: { Separator, Loader },
  props: {
    form: {
      type: Object as PropType<DiscountFormInput>,
      required: true,
    },
  },
  data() {
    return {
      appliesTo: AppliesToEnum.Everyone as AppliesToEnum,
      customers: [] as Customer[],
      isLoading: false,
    }
  },
  computed: {
    discount(): DiscountFormInput {
      return this.form
    },
    shouldShowSelectCustomers(): boolean {
      return this.appliesTo === AppliesToEnum.Customers
    },
    prerequisiteCustomerIds: {
      get(): string[] {
        return this.discount.rules.prerequisiteCustomerIds as string[]
      },
      set(value: string[]) {
        this.discount.rules.prerequisiteCustomerIds = value
      },
    },
  },

  watch: {
    appliesTo() {
      if (this.appliesTo === AppliesToEnum.Everyone) {
        this.prerequisiteCustomerIds = []
      }
    },
  },
  async created() {
    this.appliesTo =
      this.prerequisiteCustomerIds?.length > 0
        ? AppliesToEnum.Customers
        : AppliesToEnum.Everyone
    await this.updateCustomersItems(this.prerequisiteCustomerIds)
  },

  methods: {
    viewCustomer(customerId: string) {
      this.$router.push(`/customers/${customerId}`)
    },
    async updateCustomersItems(customerIds: string[]) {
      try {
        if (!customerIds) {
          return
        }

        this.isLoading = true
        // Go through all new customerId and fetch full Customer object
        const newCustomers = await Promise.all(
          customerIds
            .filter(
              (customerId) =>
                !this.customers.find((data) => data.customerId === customerId)
            )
            .map(getShortCustomerById)
        )
        this.customers.push(...(newCustomers as Customer[]))
      } catch (e) {
        console.error('Could not fetch customer', e)
      } finally {
        this.isLoading = false
      }
    },
    getCustomerName(customerId: string) {
      const result = this.customers.find(
        (customer) => customer.customerId === customerId
      )
      return `${result?.firstName || ''} ${result?.lastName || ''}`
    },
    getCustomerEmail(customerId: string) {
      const result = this.customers.find(
        (customer) => customer.customerId === customerId
      )
      return `${result?.email || customerId}`
    },
    removeCustomer(customerId: string) {
      this.prerequisiteCustomerIds = this.prerequisiteCustomerIds.filter(
        (item) => item !== customerId
      )
    },
    openSelectCustomerModal() {
      this.$buefy.modal.open({
        parent: this,
        component: CustomersModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          selectedCustomerIds: this.prerequisiteCustomerIds || [],
        },
        events: {
          save: async (
            selectedCustomerIds: string[],
            closeModal: () => void
          ) => {
            closeModal()
            await this.updateCustomersItems(selectedCustomerIds)
            this.prerequisiteCustomerIds = selectedCustomerIds
          },
        },
      })
    },
  },
})

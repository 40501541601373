





























import Vue, { PropType } from 'vue'
import { DiscountFormInput, Maybe } from '../../../../shared/types/types'

export default Vue.extend({
  name: 'DiscountFormDates',
  props: {
    form: {
      type: Object as PropType<DiscountFormInput>,
      required: true,
    },
  },
  data: () => {
    return {
      startsAtValue: null as Maybe<string>,
      endsAtValue: null as Maybe<string>,
      enableEndsAt: true as boolean,
    }
  },
  computed: {
    discount(): DiscountFormInput {
      return this.form
    },
    startsAtDate: {
      get(): Date | null {
        return this.startsAtValue ? new Date(this.startsAtValue) : null
      },
      set(value: Date | null) {
        this.startsAtValue = value?.toISOString() || null
      },
    },
    endsAtDate: {
      get(): Date | null {
        return this.endsAtValue ? new Date(this.endsAtValue) : null
      },
      set(value: Date | null) {
        this.endsAtValue = value?.toISOString() || null
      },
    },
  },
  watch: {
    form() {
      this.updateInitialValues()
    },
    enableEndsAt() {
      this.discount.rules.endsAt = this.enableEndsAt
        ? new Date().toISOString()
        : null
      this.endsAtValue = this.discount.rules.endsAt
    },
    startsAtDate() {
      if (this.startsAtDate) {
        this.discount.rules.startsAt = this.startsAtDate.toISOString()
      }
    },
    endsAtDate() {
      this.discount.rules.endsAt = this.endsAtDate?.toISOString()
    },
  },
  created() {
    this.updateInitialValues()
  },
  methods: {
    updateInitialValues() {
      this.startsAtValue =
        this.discount?.rules?.startsAt || new Date().toISOString()
      this.endsAtValue = this.discount?.rules?.endsAt || null
      this.enableEndsAt = !!this.endsAtValue
    },
  },
})

import gql from 'graphql-tag'

const DISCOUNT_OBJECT = `
  name
  type
  status
  usage
  currency
  summary
  rules {
    type
    value {
      ... on DiscountPriceValue {
        amount
        currency
        type
      }
      ... on DiscountScalarValue {
        value
      }
    }
    startsAt
    endsAt
    usageLimit
    usageOncePerUser
    prerequisiteCustomerIds
    prerequisiteMinPurchaseAmount {
      amount
      currency
    }
    prerequisiteMinQuantity
    entitledCountryCodes
    entitledProductVariantIds {
      productId
      variantId
    }
    prerequisiteProductVariantIds {
      productId
      variantId
    }
    prerequisiteToEntitlement {
      entitledQuantity
      prerequisiteQuantity
      prerequisitePurchaseAmount {
        amount
        currency
      }
      maxNumberOfUses
    }
  }
`

/**
 * Create a discount
 */
export const CREATE_DISCOUNT = gql`
  mutation createDiscount($input: DiscountFormInput!) {
    createDiscount(input: $input) {
      ${DISCOUNT_OBJECT}
    }
  }
`

/**
 * Update a discount
 */
export const UPDATE_DISCOUNT = gql`
  mutation updateDiscount($discountId: ID!, $input: DiscountFormInput!) {
    updateDiscount(discountId: $discountId, input: $input) {
      ${DISCOUNT_OBJECT}
    }
  }
`

/**
 * Query to get a discount for the specified discountId
 */
export const GET_DISCOUNT = gql`
  query getDiscount($discountId: ID!) {
    getDiscount(discountId: $discountId) {
      ${DISCOUNT_OBJECT}
    }
  }
`

/**
 * Mutation to delete discount for the specified discountId
 */
export const DELETE_DISCOUNT = gql`
  mutation deleteDiscount($discountId: ID!) {
    deleteDiscount(discountId: $discountId) {
      successful
    }
  }
`

/**
 * Mutation to bulk delete discounts for the specified discountIds
 */
export const DELETE_DISCOUNTS = gql`
  mutation deleteDiscounts($discountIds: [ID]!) {
    deleteDiscounts(discountIds: $discountIds) {
      successful
    }
  }
`

/**
 * Mutation to bulk enable discounts for the specified discountIds
 */
export const ENABLE_DISCOUNTS = gql`
  mutation enableDiscounts($discountIds: [ID]!) {
    enableDiscounts(discountIds: $discountIds) {
      successful
    }
  }
`

/**
 * Mutation to bulk disable discounts for the specified discountIds
 */
export const DISABLE_DISCOUNTS = gql`
  mutation disableDiscounts($discountIds: [ID]!) {
    disableDiscounts(discountIds: $discountIds) {
      successful
    }
  }
`

export const GET_DISCOUNT_CURRENCIES = gql`
  query getDiscountCurrencies($discountId: ID!) {
    getDiscountCurrencies(discountId: $discountId) {
      currencies {
        ${DISCOUNT_OBJECT}
      }
    }
  }
`























































































import Vue from 'vue'
import DiscountFormBuyXGetY from '../../components/discounts/DiscountFormBuyXGetY.vue'
import DiscountFormCountries from '../../components/discounts/DiscountFormCountries.vue'
import DiscountFormCustomers from '../../components/discounts/DiscountFormCustomers.vue'
import DiscountFormDates from '../../components/discounts/DiscountFormDates.vue'
import DiscountFormMinRequirements from '../../components/discounts/DiscountFormMinRequirements.vue'
import DiscountFormName from '../../components/discounts/DiscountFormName.vue'
import DiscountFormSummary from '../../components/discounts/DiscountFormSummary.vue'
import DiscountFormTypes from '../../components/discounts/DiscountFormTypes.vue'
import DiscountFormUsageLimit from '../../components/discounts/DiscountFormUsageLimit.vue'
import DiscountFormValue from '../../components/discounts/DiscountFormValue.vue'
import Loader from '../../components/shared/Loader.vue'
import NavigateBack from '../../components/shared/NavigateBack.vue'
import {
  getDiscount,
  generateEmptyDiscount,
  deleteDiscount,
  updateDiscount,
  createDiscount,
  disableDiscounts,
  enableDiscounts,
  syncDiscountCurrencies,
} from '../../lib/discount'
import {
  DiscountFormInput,
  DiscountType,
  DiscountRuleType,
  DiscountStatusType,
} from '../../../../shared/types/types'

export default Vue.extend({
  name: 'DiscountDetails',
  components: {
    NavigateBack,
    Loader,
    DiscountFormCountries,
    DiscountFormBuyXGetY,
    DiscountFormDates,
    DiscountFormMinRequirements,
    DiscountFormName,
    DiscountFormCustomers,
    DiscountFormSummary,
    DiscountFormTypes,
    DiscountFormUsageLimit,
    DiscountFormValue,
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      isSyncing: false,
      discount: null as DiscountFormInput | null,
    }
  },
  computed: {
    isPageEditMode(): boolean {
      return !this.$route.path.includes('/new')
    },
    discountType(): DiscountType {
      return this.$route.path.includes('/code/')
        ? DiscountType.Code
        : DiscountType.Automatic
    },
    discountId(): string {
      return this.$route.params.discountId
    },
    isDiscountLoaded(): boolean {
      return !!this.discount
    },
    shouldShowDisbale(): boolean {
      return (
        this.isPageEditMode &&
        this.discount?.status !== DiscountStatusType.Expired
      )
    },
    shouldShowEnable(): boolean {
      return (
        this.isPageEditMode &&
        this.discount?.status !== DiscountStatusType.Active
      )
    },
    shouldShowValue(): boolean {
      return (
        this.discount?.rules?.type === DiscountRuleType.Percentage ||
        this.discount?.rules?.type === DiscountRuleType.FixedAmount
      )
    },
    shouldShowBuyXGetY(): boolean {
      return this.discount?.rules?.type === DiscountRuleType.BuyXgetY
    },
    shouldShowCountries(): boolean {
      return this.discount?.rules?.type === DiscountRuleType.FreeShipping
    },
    saveTitle(): string {
      return this.isPageEditMode ? 'Save' : 'Create'
    },
  },

  created() {
    this.getDiscountDetails()
  },
  methods: {
    cardTitle(title: string, number: number) {
      return `${title} (${number})`
    },
    async getDiscountDetails() {
      try {
        if (this.isPageEditMode) {
          const discount = await getDiscount(this.discountId)
          this.discount = discount as DiscountFormInput
        } else {
          this.discount = generateEmptyDiscount(this.discountType)
        }
      } catch (error) {
        this.$buefy.toast.open({
          message: 'Discount not found',
          position: 'is-bottom',
          type: 'is-danger',
        })
      }
    },
    async saveDiscount() {
      try {
        if (this.discount) {
          this.isSaving = true
          if (this.isPageEditMode) {
            await updateDiscount(this.discountId, this.discount)
          } else {
            await createDiscount(this.discount)
          }
        }
        this.$router.push('/discounts')
      } catch (error) {
        this.$buefy.toast.open({
          message:
            error instanceof Error ? error.message : 'Something went wrong',
          position: 'is-bottom',
          type: 'is-danger',
        })
      } finally {
        this.isSaving = false
      }
    },

    async enableDiscount(): Promise<void> {
      try {
        this.isLoading = true
        await enableDiscounts([this.discountId])
        await this.getDiscountDetails()
        this.$buefy.toast.open({
          message: 'Discount enabled.',
          position: 'is-top',
          type: 'is-success',
        })
      } catch (e) {
        this.$buefy.toast.open({
          message: e instanceof Error ? e.message : 'Something went wrong',
          position: 'is-bottom',
          type: 'is-danger',
        })
      } finally {
        this.isLoading = false
      }
    },
    async disableDiscount(): Promise<void> {
      try {
        this.isLoading = true
        await disableDiscounts([this.discountId])
        await this.getDiscountDetails()
        this.$buefy.toast.open({
          message: 'Discount disabled.',
          position: 'is-top',
          type: 'is-success',
        })
      } catch (e) {
        this.$buefy.toast.open({
          message: e instanceof Error ? e.message : 'Something went wrong',
          position: 'is-bottom',
          type: 'is-danger',
        })
      } finally {
        this.isLoading = false
      }
    },
    async deleteDiscount() {
      try {
        this.isSaving = true
        await deleteDiscount(this.discountId)
        this.$buefy.toast.open({
          message: 'Discount deleted.',
          position: 'is-top',
          type: 'is-success',
        })
        this.$router.push('/discounts')
      } catch (error) {
        this.$buefy.toast.open({
          message:
            error instanceof Error ? error.message : 'Something went wrong',
          position: 'is-bottom',
          type: 'is-danger',
        })
      } finally {
        this.isSaving = false
      }
    },
    async syncDiscount() {
      try {
        this.isSyncing = true
        await syncDiscountCurrencies(this.discountId)
        this.$buefy.toast.open({
          message: 'Discount exchange rates updated',
          position: 'is-top',
          type: 'is-success',
        })
      } catch (error) {
        this.$buefy.toast.open({
          message: 'Something went wrong',
          position: 'is-bottom',
          type: 'is-danger',
        })
      } finally {
        this.isSyncing = false
      }
    },
  },
})

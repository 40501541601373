
















































enum AppliesToEnum {
  AllCountries = 'allCountries',
  Countries = 'countries',
}

import Vue, { PropType } from 'vue'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import CountriesModal from '@/components/shared/CountriesModal.vue'
import { DiscountFormInput } from '../../../../shared/types/types'
import { data as countriesData } from '@/assets/countries.json'
import Separator from '../shared/Separator.vue'

interface DataCountry {
  name: string
  code: string
}

export default Vue.extend({
  name: 'DiscountFormCountries',
  components: { Separator },
  props: {
    form: {
      type: Object as PropType<DiscountFormInput>,
      required: true,
    },
  },
  data() {
    return {
      appliesTo: AppliesToEnum.AllCountries as AppliesToEnum,
    }
  },
  computed: {
    discount(): DiscountFormInput {
      return this.form
    },
    shouldShowSelectCountries(): boolean {
      return this.appliesTo === AppliesToEnum.Countries
    },
    entitledCountryCodes: {
      get(): string[] {
        return this.discount.rules.entitledCountryCodes as string[]
      },
      set(value: string[]) {
        this.discount.rules.entitledCountryCodes = value
      },
    },
  },
  created() {
    this.appliesTo =
      this.entitledCountryCodes?.length > 0
        ? AppliesToEnum.Countries
        : AppliesToEnum.AllCountries
  },

  methods: {
    getCountryFlag(countryCode: string) {
      return getUnicodeFlagIcon(countryCode)
    },
    getCountryName(countryCode: string) {
      return (countriesData as DataCountry[]).filter(
        (data) => data.code === countryCode
      )[0].name
    },
    removeCountry(countryCode: string) {
      this.entitledCountryCodes = this.entitledCountryCodes?.filter(
        (item) => item !== countryCode
      )
    },
    openSelectCountryModal() {
      this.$buefy.modal.open({
        parent: this,
        component: CountriesModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          selectedCountryCodes: this.entitledCountryCodes || [],
        },
        events: {
          save: async (
            selectedCountryCodes: string[],
            closeModal: () => void
          ) => {
            this.entitledCountryCodes = JSON.parse(
              JSON.stringify(selectedCountryCodes)
            )
            closeModal()
          },
        },
      })
    },
  },
})
































import Vue, { PropType } from 'vue'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { data as countriesData } from '@/assets/countries.json'

interface DataCountry {
  name: string
  code: string
}

export default Vue.extend({
  name: 'CountriesModal',
  props: {
    selectedCountryCodes: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  data() {
    return {
      selectedCodes: [] as string[],
      filterText: '',
    }
  },
  computed: {
    countries(): DataCountry[] {
      if (!this.filterText || this.filterText.length === 0) {
        return countriesData
      }
      return countriesData.filter((country) => {
        const search =
          country.name.toLowerCase().search(this.filterText.toLowerCase()) !==
          -1
        return search
      })
    },
  },
  async created() {
    this.selectedCodes = this.selectedCountryCodes
  },
  methods: {
    getCountryFlag(countryCode: string) {
      return getUnicodeFlagIcon(countryCode)
    },
    handleClose() {
      this.$emit('close')
    },
    handleSave() {
      this.$emit('save', this.selectedCodes, this.handleClose)
    },
  },
})









































import Vue, { PropType } from 'vue'
import Separator from '../shared/Separator.vue'
import { Discount, DiscountType } from '../../../../shared/types/types'
import { getSummaryDetails } from '@/lib/discount'

export default Vue.extend({
  name: 'DiscountFormSummary',
  components: {
    Separator,
  },
  props: {
    form: {
      type: Object as PropType<Discount>,
      required: true,
    },
  },
  computed: {
    discount(): Discount {
      return this.form
    },
    discountName(): string {
      return this.discount.name
    },
    shouldShowAutomaticDiscount(): boolean {
      return this.discount.type === DiscountType.Automatic
    },
    summaryDetails(): string[] {
      return getSummaryDetails(this.discount)
    },
    isPageEditMode(): boolean {
      return !this.$route.path.includes('/new')
    },
    used(): string {
      return `${this.discount.usage || 0} used`
    },
  },
})

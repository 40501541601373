


































import { PropType } from 'vue'
import mixins from 'vue-typed-mixins'
import debounce from 'just-debounce-it'
import { getElasticSearchClient } from '@/services/elasticSearch'
import CustomersTable from '@/mixins/CustomersTable.mixin'

interface Row {
  customerId: string
  firstName: string
  lastName: string
  email: string
  createdAt: Date
  updatedAt: Date
}

export default mixins(CustomersTable).extend({
  name: 'CustomersModal',
  props: {
    selectedCustomerIds: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  data() {
    return {
      checkedRows: [] as Row[],
      currentPage: 1,
      isLoading: false,
      itemsPerPage: 10,
      search: '',
      searchInput: '',
      searchResult: [] as Record<string, any>,
      selectedIds: [] as string[],
    }
  },
  computed: {
    query(): Record<string, any> {
      const query: Record<string, any> = {
        query: {
          bool: {
            filter: [],
            must: [],
          },
        },
        track_total_hits: true,
        sort: this.sort,
        size: this.itemsPerPage,
        from: (this.currentPage - 1) * this.itemsPerPage,
      }
      if (this.search && this.search.length > 0) {
        if (
          this.search.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
        ) {
          // when search input is an email, search for customer email
          query.query.bool.filter.push({
            match_phrase: {
              email: this.search,
            },
          })
        } else {
          // when search input is a string, search for customer name and product title
          query.query.bool.must.push({
            match: {
              fullName: {
                query: this.search,
              },
            },
          })
          // delete query.sort
        }
      }
      // if (this.search && this.search.length > 0) {
      //   query.query.bool.filter.push({
      //     multi_match: {
      //       query: this.search,
      //       fields: ['fullName'],
      //       fuzziness: 0,
      //       // prefix_length: 0,
      //     },
      //   })
      // }

      if (
        query.query.bool.filter.length === 0 &&
        query.query.bool.must.length === 0
      ) {
        query.query = {
          match_all: {},
        }
      }

      return query
    },
    nbHits(): number {
      return this.searchResult && this.searchResult.hits
        ? this.searchResult.hits.total.value
        : 0
    },
    rows(): Row[] {
      if (!this.searchResult || this.searchResult.length === 0) {
        return []
      }
      return this.searchResult.hits.hits.map((h: { _source: Row }) => h._source)
    },
    checkedProductIds(): string[] {
      return this.checkedRows.map((row) => row.customerId)
    },
  },
  watch: {
    searchInput: {
      immediate: true,
      handler: debounce(function () {
        //@ts-ignore
        this.search = this.searchInput.trim()
      }, 500),
    },
    query: {
      immediate: true,
      deep: true,
      handler() {
        this.fetchData()
      },
    },
    rows(rows: Row[]) {
      this.checkedRows = rows.filter((row) =>
        this.selectedCustomerIds.includes(row.customerId)
      )
    },
  },
  async created() {
    this.selectedIds = this.selectedCustomerIds
  },
  methods: {
    debouncedSearchInput: debounce(function (this: any, value: string) {
      this.search = value
    }, 500),
    async fetchData(): Promise<void> {
      const elasticSearch = getElasticSearchClient()
      const { data } = await elasticSearch.post(
        '/customers/_search',
        this.query
      )
      this.searchResult = data
    },
    /**
     * Set loading state
     *
     * @param isLoading - current loading state
     */
    setLoading(isLoading: boolean) {
      this.isLoading = isLoading
    },
    /**
     * Close the modal
     */
    close() {
      this.$emit('close')
    },
    /**
     * Method to handle save button
     *
     * Emits an event with selected product variants & close method
     */
    async handleSave() {
      this.setLoading(true)
      this.$emit('save', this.selectedIds, this.close)
    },
  },
})

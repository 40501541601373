







































































































enum AppliesToEnum {
  AllProducts = 'allProducts',
  Product = 'products',
}

import Vue, { PropType } from 'vue'
import Separator from '@/components/shared/Separator.vue'
import AddProductVariantsModal from '@/components/products/AddProductVariantsModal.vue'
import Loader from '@/components/shared/Loader.vue'
import CurrencyInput from '../shared/CurrencyInput.vue'
import PercentageInput from '../shared/PercentageInput.vue'
import {
  CurrencyEnum,
  Discount,
  DiscountFormInput,
  DiscountPriceValue,
  DiscountRuleType,
  DiscountValueType,
  Maybe,
  Product,
} from '../../../../shared/types/types'
import { ProductVariantKey } from '@/types'
import { getProduct } from '@/lib/product'
import CurrenciesModal from '@/components/shared/CurrenciesModal.vue'
import { getDiscountCurrencies, updateDiscountCurrencies } from '@/lib/discount'

export default Vue.extend({
  name: 'DiscountFormValue',
  components: {
    CurrencyInput,
    Loader,
    PercentageInput,
    Separator,
  },
  props: {
    form: {
      type: Object as PropType<DiscountFormInput>,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      discountValue: 0 as number,
      appliesTo: AppliesToEnum.AllProducts as AppliesToEnum,
      usageOncePerUser: null as Maybe<boolean>,
      products: [] as Product[],
      isLoading: false as boolean,
      currencies: [] as Discount[],
    }
  },
  computed: {
    discount(): DiscountFormInput {
      return this.form
    },
    discountRuleType(): DiscountRuleType {
      return this.discount.rules.type
    },
    shouldShowCurrencyInput(): boolean {
      return this.discountRuleType === DiscountRuleType.FixedAmount
    },
    shouldShowPercentageInput(): boolean {
      return this.discountRuleType === DiscountRuleType.Percentage
    },
    shouldShowUsageOncePerUser(): boolean {
      return this.appliesTo === AppliesToEnum.Product
    },
    shouldShowAddProduct(): boolean {
      return this.appliesTo === AppliesToEnum.Product
    },
    entitledProductVariantIds: {
      get(): ProductVariantKey[] {
        return this.discount.rules
          .entitledProductVariantIds as ProductVariantKey[]
      },
      set(value: ProductVariantKey[]) {
        this.discount.rules.entitledProductVariantIds = value
      },
    },
    hasProducts(): boolean {
      return this.products.length > 0
    },
  },
  watch: {
    usageOncePerUser() {
      this.discount.rules.usageOncePerUser = this.usageOncePerUser
    },
    entitledProductVariantIds() {
      this.updateProducts()
    },
    discountRuleType() {
      // Whenever the type changes it sets default values
      if (this.discountRuleType === DiscountRuleType.FixedAmount) {
        this.discount.rules.value = {
          amount: 0,
          currency: CurrencyEnum.Usd,
        }
      } else if (this.discountRuleType === DiscountRuleType.Percentage) {
        this.discount.rules.value = {
          value: 0,
        }
      }
    },
    appliesTo() {
      if (this.appliesTo === AppliesToEnum.AllProducts) {
        this.entitledProductVariantIds = []
      }
    },
  },
  async created() {
    if (this.discountRuleType === DiscountRuleType.FixedAmount) {
      this.discountValue = this.discount?.rules?.value?.amount
    } else if (this.discountRuleType === DiscountRuleType.Percentage) {
      this.discountValue = this.discount?.rules?.value?.value
    }
    this.usageOncePerUser = this.discount?.rules?.usageOncePerUser || false
    this.appliesTo =
      this.entitledProductVariantIds?.length > 0
        ? AppliesToEnum.Product
        : AppliesToEnum.AllProducts
    this.updateProducts()

    if (this.editMode) {
      const data = await getDiscountCurrencies(this.$route.params.discountId)
      this.currencies = data.currencies.slice()
    }
  },

  methods: {
    filteredProducts(productVariantIds: ProductVariantKey[]): Product[] {
      return (
        productVariantIds
          .map(({ productId, variantId }) => {
            const found = this.products.find(
              (product) => product.productId === productId
            )
            if (found) {
              // Make a copy of product because variants attribute is modified
              const newProduct = JSON.parse(JSON.stringify(found)) as Product
              newProduct.variants = found.variants.filter(
                (variant) => variant.variantId === variantId
              )
              return newProduct
            }
          })
          .filter((data) => data)
          // Reduce results by combining products together - merge by productId
          .reduce((prev, current) => {
            const found = prev.find(
              ({ productId }) => productId === current?.productId
            )
            if (found) {
              found.variants.push(...(current?.variants || []))
            } else if (current) {
              prev.push(current)
            }
            return prev
          }, [] as Product[])
      )
    },
    setValue(value: number, type: DiscountRuleType) {
      this.discountValue = value

      // Update discount object
      if (type === DiscountRuleType.FixedAmount) {
        this.discount.rules.value = {
          amount: value,
          currency: CurrencyEnum.Usd,
        }
      } else if (type === DiscountRuleType.Percentage) {
        this.discount.rules.value = {
          value,
        }
      }
    },
    removeProductVariant(productId: string, variantId: string) {
      this.entitledProductVariantIds = this.entitledProductVariantIds.filter(
        (item) => {
          const match =
            item.productId === productId && item.variantId === variantId
          return !match
        }
      )
    },
    openAddProductVariantsModal() {
      this.$buefy.modal.open({
        parent: this,
        component: AddProductVariantsModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          existingProductVariants: this.entitledProductVariantIds,
        },
        events: {
          save: async (
            selectedProductVariants: ProductVariantKey[],
            closeModal: () => void
          ) => {
            this.entitledProductVariantIds = selectedProductVariants
            closeModal()
          },
        },
      })
    },
    async updateProducts() {
      this.isLoading = true
      const newProducts: Product[] = []
      for (const item of this.entitledProductVariantIds || []) {
        const foundProduct = this.products.find(
          (product) => product.productId === item.productId
        )
        if (foundProduct) {
          newProducts.push(foundProduct)
        } else {
          const result = await getProduct(item.productId)
          if (result) {
            newProducts.push(result)
          }
        }
      }
      this.products = newProducts
      this.isLoading = false
    },
    openCurrenciesModal() {
      const discounts = JSON.parse(JSON.stringify(this.currencies))

      this.$buefy.modal.open({
        parent: this,
        component: CurrenciesModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          currencies: this.currencies.map((discount) => ({
            amount:
              discount.rules?.value?.type === DiscountValueType.Price
                ? (discount.rules.value as DiscountPriceValue).amount
                : 0,
            currency:
              discount.rules?.value?.type === DiscountValueType.Price
                ? (discount.rules.value as DiscountPriceValue).currency ||
                  CurrencyEnum.Usd
                : CurrencyEnum.Usd,
          })),
          async save(currencies: Discount[]) {
            this.loading = false

            const updatedDiscountCurrencies = discounts.map(
              (discount: any) =>
                ({
                  ...discount,
                  rules: {
                    ...discount.rules,
                    value: JSON.stringify(
                      currencies.find(
                        (currency) => currency.currency === discount.currency
                      )
                    ),
                  },
                } as DiscountFormInput)
            )

            await updateDiscountCurrencies(
              this.$route.params.discountId,
              updatedDiscountCurrencies
            )

            this.loading = true
          },
        },
      })
    },
  },
})





























































































































































































enum MinTypeEnum {
  MinQuantity = 'minQuantity',
  MinAmount = 'minAmount',
}

enum DiscountValueEnum {
  Percentage = 'percentage',
  Free = 'free',
}

import Vue, { PropType } from 'vue'
import Separator from '@/components/shared/Separator.vue'
import AddProductVariantsModal from '@/components/products/AddProductVariantsModal.vue'
import CurrencyInput from '../shared/CurrencyInput.vue'
import PercentageInput from '../shared/PercentageInput.vue'
import {
  CurrencyEnum,
  DiscountFormInput,
  DiscountRuleType,
  Product,
} from '../../../../shared/types/types'
import { ProductVariantKey } from '@/types'
import { getProduct } from '@/lib/product'

export default Vue.extend({
  name: 'DiscountFormBuyXGetY',
  components: {
    CurrencyInput,
    PercentageInput,
    Separator,
  },
  props: {
    form: {
      type: Object as PropType<DiscountFormInput>,
      required: true,
    },
  },
  data() {
    return {
      minType: MinTypeEnum.MinQuantity as MinTypeEnum,
      valueType: DiscountValueEnum.Free as DiscountValueEnum,
      products: [] as Product[],
      minAmount: 0 as number,
      minQuantity: '0' as string,
      getQuantity: '0' as string,
      discountValue: 0 as number,
      isLoading: false as boolean,
    }
  },
  computed: {
    discount(): DiscountFormInput {
      return this.form
    },
    discountRuleType(): DiscountRuleType {
      return this.discount.rules.type
    },
    shouldShowCurrencyInput(): boolean {
      return this.minType === MinTypeEnum.MinAmount
    },
    shouldShowQuantityInput(): boolean {
      return this.minType === MinTypeEnum.MinQuantity
    },
    shouldShowPercentageInput(): boolean {
      return this.valueType === DiscountValueEnum.Percentage
    },
    buyTitle(): string {
      switch (this.minType) {
        case MinTypeEnum.MinAmount:
          return 'Customer buys'
        case MinTypeEnum.MinQuantity:
          return 'Customer spends'
        default:
          return ''
      }
    },
    getTitle(): string {
      return 'Customer gets'
    },
    entitledProductVariantIds: {
      get(): ProductVariantKey[] {
        return this.discount.rules
          .entitledProductVariantIds as ProductVariantKey[]
      },
      set(value: ProductVariantKey[]) {
        this.discount.rules.entitledProductVariantIds = value
      },
    },
    prerequisiteProductVariantIds: {
      get(): ProductVariantKey[] {
        return this.discount.rules
          .prerequisiteProductVariantIds as ProductVariantKey[]
      },
      set(value: ProductVariantKey[]) {
        this.discount.rules.prerequisiteProductVariantIds = value
      },
    },
    hasBuyProducts(): boolean {
      return this.filteredProducts(this.entitledProductVariantIds).length > 0
    },
    hasGetProducts(): boolean {
      return (
        this.filteredProducts(this.prerequisiteProductVariantIds).length > 0
      )
    },
  },
  watch: {
    entitledProductVariantIds() {
      this.updateProducts()
    },
    prerequisiteProductVariantIds() {
      this.updateProducts()
    },
    minType() {
      if (this.minType === MinTypeEnum.MinAmount) {
        if (this.discount.rules.prerequisiteToEntitlement) {
          this.discount.rules.prerequisiteToEntitlement.prerequisitePurchaseAmount = {
            amount: 0,
            currency: CurrencyEnum.Usd,
          }
        } else {
          this.discount.rules.prerequisiteToEntitlement = {
            prerequisitePurchaseAmount: {
              amount: 0,
              currency: CurrencyEnum.Usd,
            },
          }
        }
      } else if (this.minType === MinTypeEnum.MinQuantity) {
        if (this.discount.rules.prerequisiteToEntitlement) {
          this.discount.rules.prerequisiteToEntitlement.prerequisiteQuantity = 0
        } else {
          this.discount.rules.prerequisiteToEntitlement = {
            prerequisiteQuantity: 0,
          }
        }
      }
    },
    valueType() {
      this.discount.rules.value = {
        value:
          this.valueType === DiscountValueEnum.Free
            ? 100
            : this.discountValue || 0,
      }
    },
    getQuantity() {
      const quantity = parseInt(this.getQuantity)
      if (this.discount.rules?.prerequisiteToEntitlement) {
        this.discount.rules.prerequisiteToEntitlement.entitledQuantity = quantity
      } else {
        this.discount.rules.prerequisiteToEntitlement = {
          prerequisiteQuantity: quantity,
        }
      }
    },
    minQuantity() {
      const value = parseInt(this.minQuantity)
      if (this.discount.rules?.prerequisiteToEntitlement) {
        this.discount.rules.prerequisiteToEntitlement.prerequisiteQuantity = value
      } else {
        this.discount.rules.prerequisiteToEntitlement = {
          prerequisiteQuantity: value,
        }
      }
    },
  },
  created() {
    this.discountValue =
      this.discount.rules?.value?.value !== undefined
        ? this.discount.rules?.value?.value
        : 0

    this.getQuantity = (
      this.discount.rules?.prerequisiteToEntitlement?.entitledQuantity || 0
    ).toString()
    this.minQuantity = (
      this.discount.rules?.prerequisiteToEntitlement?.prerequisiteQuantity || 0
    ).toString()
    this.minAmount =
      this.discount.rules?.prerequisiteToEntitlement?.prerequisitePurchaseAmount
        ?.amount || 0

    this.valueType =
      this.discount.rules?.value?.value === 100
        ? DiscountValueEnum.Free
        : DiscountValueEnum.Percentage
    this.minType = this.discount.rules?.prerequisiteToEntitlement
      ?.entitledQuantity
      ? MinTypeEnum.MinQuantity
      : MinTypeEnum.MinAmount

    this.updateProducts()
  },

  methods: {
    filteredProducts(productVariantIds: ProductVariantKey[]): Product[] {
      return (
        productVariantIds
          .map(({ productId, variantId }) => {
            const found = this.products.find(
              (product) => product.productId === productId
            )
            if (found) {
              // Make a copy of product because variants attribute is modified
              const newProduct = JSON.parse(JSON.stringify(found)) as Product
              newProduct.variants = found.variants.filter(
                (variant) => variant.variantId === variantId
              )
              return newProduct
            }
          })
          .filter((data) => data)
          // Reduce results by combining products together - merge by productId
          .reduce((prev, current) => {
            const found = prev.find(
              ({ productId }) => productId === current?.productId
            )
            if (found) {
              found.variants.push(...(current?.variants || []))
            } else if (current) {
              prev.push(current)
            }
            return prev
          }, [] as Product[])
      )
    },
    setMinAmount(value: number) {
      if (this.discount.rules?.prerequisiteToEntitlement) {
        if (
          this.discount.rules.prerequisiteToEntitlement
            .prerequisitePurchaseAmount
        ) {
          this.discount.rules.prerequisiteToEntitlement.prerequisitePurchaseAmount.amount = value
        } else {
          this.discount.rules.prerequisiteToEntitlement = {
            prerequisitePurchaseAmount: {
              amount: value,
              currency: CurrencyEnum.Usd,
            },
          }
        }
      } else {
        this.discount.rules.prerequisiteToEntitlement = {
          prerequisitePurchaseAmount: {
            amount: value,
            currency: CurrencyEnum.Usd,
          },
        }
      }
    },
    setDiscountValue(value: number) {
      this.discountValue = value
      if (this.discount.rules.value === undefined) {
        this.discount.rules.value = {
          value,
        }
      } else {
        this.discount.rules.value.value = value
      }
    },
    removeBuyXProductVariant(productId: string, variantId: string) {
      this.entitledProductVariantIds = this.entitledProductVariantIds.filter(
        (item) => {
          const match =
            item.productId === productId && item.variantId === variantId
          return !match
        }
      )
    },
    removeGetYProductVariant(productId: string, variantId: string) {
      this.prerequisiteProductVariantIds = this.prerequisiteProductVariantIds.filter(
        (item) => {
          const match =
            item.productId === productId && item.variantId === variantId
          return !match
        }
      )
    },
    openBuyXProductVariantsModal() {
      this.$buefy.modal.open({
        parent: this,
        component: AddProductVariantsModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          existingProductVariants: this.entitledProductVariantIds,
        },
        events: {
          save: async (
            selectedProductVariants: ProductVariantKey[],
            closeModal: () => void
          ) => {
            this.entitledProductVariantIds = selectedProductVariants
            closeModal()
          },
        },
      })
    },
    openGetYProductVariantsModal() {
      this.$buefy.modal.open({
        parent: this,
        component: AddProductVariantsModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          existingProductVariants: this.prerequisiteProductVariantIds,
        },
        events: {
          save: async (
            selectedProductVariants: ProductVariantKey[],
            closeModal: () => void
          ) => {
            this.prerequisiteProductVariantIds = selectedProductVariants
            closeModal()
          },
        },
      })
    },
    async updateProducts() {
      this.isLoading = true
      const newProducts: Product[] = []
      const productVariantIds = [
        ...(this.entitledProductVariantIds || []),
        ...(this.prerequisiteProductVariantIds || []),
      ]
      for (const item of productVariantIds) {
        const foundProduct = this.products.find(
          (product) => product.productId === item.productId
        )
        if (foundProduct) {
          newProducts.push(foundProduct)
        } else {
          const result = await getProduct(item.productId)
          if (result) {
            newProducts.push(result)
          }
        }
      }
      this.products = newProducts
      this.isLoading = false
    },
  },
})





















import Vue, { PropType } from 'vue'
import { DiscountFormInput } from '../../../../shared/types/types'
import { capitalizeFirstLetter } from '@/lib/utils'

export default Vue.extend({
  name: 'DiscountFormName',
  props: {
    form: {
      type: Object as PropType<DiscountFormInput>,
      required: true,
    },
  },
  computed: {
    discount(): DiscountFormInput {
      return this.form
    },
    discountHeader(): string {
      return this.discount
        ? `${capitalizeFirstLetter(this.discount.type)} discount`
        : ''
    },
    desc(): string {
      return this.discount.type === 'code'
        ? 'Customers will enter this discount code at checkout.'
        : 'Customers will see this in cart and at checkout.'
    },
  },
  methods: {
    generateCode() {
      const code = (Math.random() + 1).toString(36).substring(2)
      this.discount.name = code.toUpperCase()
    },
  },
})


















































enum RequirmentEnum {
  None = 'none',
  MinAmount = 'minAmount',
  MinQuantity = 'minQuantity',
}

import Vue, { PropType } from 'vue'
import CurrencyInput from '../shared/CurrencyInput.vue'
import {
  CurrencyEnum,
  Discount,
  Price,
  DiscountFormInput,
} from '../../../../shared/types/types'
import { getDiscountCurrencies, updateDiscountCurrencies } from '@/lib/discount'
import CurrenciesModal from '@/components/shared/CurrenciesModal.vue'

export default Vue.extend({
  name: 'DiscountFormMinRequirements',
  components: {
    CurrencyInput,
  },
  props: {
    form: {
      type: Object as PropType<DiscountFormInput>,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      requirmentType: RequirmentEnum.None,
      minAmount: 0,
      minQuantity: 0,
      currencies: [] as Discount[],
    }
  },
  computed: {
    discount(): DiscountFormInput {
      return this.form
    },
    shouldShowAmountInput(): boolean {
      return this.requirmentType === RequirmentEnum.MinAmount
    },
    shouldShowQuantityInput(): boolean {
      return this.requirmentType === RequirmentEnum.MinQuantity
    },
  },
  watch: {
    requirmentType() {
      if (this.requirmentType === RequirmentEnum.MinAmount) {
        this.minQuantity = 0
      } else if (this.requirmentType === RequirmentEnum.MinQuantity) {
        this.minAmount = 0
      } else {
        this.minAmount = 0
        this.minQuantity = 0
      }
    },
    minAmount() {
      this.discount.rules.prerequisiteMinPurchaseAmount = {
        amount: this.minAmount,
        currency: CurrencyEnum.Usd,
      }
    },
    minQuantity() {
      this.discount.rules.prerequisiteMinQuantity = this.minQuantity
    },
  },
  async created() {
    this.minAmount =
      this.discount.rules?.prerequisiteMinPurchaseAmount?.amount || 0
    this.minQuantity = this.discount.rules?.prerequisiteMinQuantity || 0
    if (this.minAmount > 0) {
      this.requirmentType = RequirmentEnum.MinAmount
    } else if (this.minQuantity > 0) {
      this.requirmentType = RequirmentEnum.MinQuantity
    } else {
      this.requirmentType = RequirmentEnum.None
    }

    if (this.editMode) {
      const data = await getDiscountCurrencies(this.$route.params.discountId)
      this.currencies = data.currencies
    }
  },
  methods: {
    setValue(value: string, type: RequirmentEnum) {
      if (type === RequirmentEnum.MinAmount) {
        this.minAmount = parseInt(value)
      }
      if (type === RequirmentEnum.MinQuantity) {
        this.minQuantity = parseInt(value)
      }
    },
    openCurrenciesModal() {
      const currencies = this.currencies

      this.$buefy.modal.open({
        parent: this,
        component: CurrenciesModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          currencies: this.currencies.slice().map((discount) => ({
            amount: discount.rules?.prerequisiteMinPurchaseAmount?.amount,
            currency: discount.rules?.prerequisiteMinPurchaseAmount?.currency,
          })),
          async save(localCurrencies: Price[]) {
            const data = currencies
              .slice()
              .map((discount: Record<string, any>) => {
                const currency = localCurrencies.find(
                  (price) => price.currency === discount.currency
                )

                return {
                  ...discount,
                  rules: {
                    ...discount.rules,
                    prerequisiteMinPurchaseAmount: currency,
                    value: JSON.stringify(discount.rules.value),
                  },
                } as DiscountFormInput
              })
            await updateDiscountCurrencies(this.$route.params.discountId, data)
          },
        },
      })
    },
  },
})

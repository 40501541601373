import apolloClient from '@/services/apollo'
import {
  GET_DISCOUNT,
  DELETE_DISCOUNT,
  DELETE_DISCOUNTS,
  ENABLE_DISCOUNTS,
  DISABLE_DISCOUNTS,
  CREATE_DISCOUNT,
  UPDATE_DISCOUNT,
  GET_DISCOUNT_CURRENCIES,
} from '@/graphql/query/discount'
import {
  Maybe,
  QueryGetDiscountArgs,
  MutationDeleteDiscountArgs,
  MutationDeleteDiscountsArgs,
  MutationEnableDiscountsArgs,
  MutationDisableDiscountsArgs,
  MutationCreateDiscountArgs,
  MutationUpdateDiscountArgs,
  Discount,
  DiscountType,
  DiscountFormInput,
  DiscountRuleType,
  DiscountSuccess,
  DiscountResponse,
  CurrencyEnum,
  DiscountScalarValue,
  Price,
  DiscountCurrenciesResponse,
  QueryGetDiscountCurrenciesArgs,
  DiscountCurrenciesInput,
  MutationUpdateDiscountCurrenciesArgs,
} from '../../../shared/types/types'
import { formatPrice } from './utils'
import {
  SYNC_DISCOUNT_CURRENCIES,
  UPDATE_DISCOUNT_CURRENCIES,
} from '@/graphql/mutation/discount'

const sanitize = (obj: DiscountFormInput) => {
  const result: DiscountFormInput = JSON.parse(
    JSON.stringify(obj, (key, value) => {
      return value === null ? undefined : value
    })
  )
  if (result.rules.value) {
    result.rules.value = JSON.stringify(result.rules.value)
  }
  return result
}

interface GetDiscountResult {
  getDiscount: Maybe<Discount>
}

class DiscountError extends Error {
  public constructor(public details: string, message?: string) {
    super(message)
  }
}

/**
 * Get discount
 *
 * @param discountId Discount ID
 */
export const getDiscount = async (
  discountId: string
): Promise<Maybe<Discount>> => {
  try {
    const { data } = await apolloClient.query<
      GetDiscountResult,
      QueryGetDiscountArgs
    >({
      query: GET_DISCOUNT,
      variables: {
        discountId,
      },
      /* Caching by apolloClient is using 'id' for creating unique reference which is causing issues in fulfillments array,
      so temporarily we are disabling cache for this query. Need to work on the right fix: https://www.apollographql.com/docs/react/caching/cache-configuration/ */
      fetchPolicy: 'no-cache',
    })
    return data.getDiscount
  } catch (e) {
    const data = JSON.parse(
      e instanceof Error ? e.message : 'Something went wrong'
    )
    const error = new DiscountError(data.details, data.message)
    console.error('Error Details: ', error.details || error.message)
    throw error
  }
}

/**
 * Delete discount
 *
 * @param discountId Discount ID
 */
export const deleteDiscount = async (
  discountId: string
): Promise<DiscountSuccess['successful']> => {
  try {
    const { data } = await apolloClient.mutate<
      DiscountSuccess,
      MutationDeleteDiscountArgs
    >({
      mutation: DELETE_DISCOUNT,
      variables: {
        discountId,
      },
      /* Caching by apolloClient is using 'id' for creating unique reference which is causing issues in fulfillments array,
    so temporarily we are disabling cache for this query. Need to work on the right fix: https://www.apollographql.com/docs/react/caching/cache-configuration/ */
      fetchPolicy: 'no-cache',
    })
    return data?.successful
  } catch (e) {
    const data = JSON.parse(
      e instanceof Error ? e.message : 'Something went wrong'
    )
    const error = new DiscountError(data.details, data.message)
    console.error('Error Details: ', error.details || error.message)
    throw error
  }
}

/**
 * Bulk delete discounts
 *
 * @param discountId[] An array of Discount IDs
 */
export const deleteDiscounts = async (
  discountIds: string[]
): Promise<DiscountSuccess['successful']> => {
  try {
    const { data } = await apolloClient.mutate<
      DiscountSuccess,
      MutationDeleteDiscountsArgs
    >({
      mutation: DELETE_DISCOUNTS,
      variables: {
        discountIds,
      },
      /* Caching by apolloClient is using 'id' for creating unique reference which is causing issues in fulfillments array,
    so temporarily we are disabling cache for this query. Need to work on the right fix: https://www.apollographql.com/docs/react/caching/cache-configuration/ */
      fetchPolicy: 'no-cache',
    })
    return data?.successful
  } catch (e) {
    const data = JSON.parse(
      e instanceof Error ? e.message : 'Something went wrong'
    )
    const error = new DiscountError(data.details, data.message)
    console.error('Error Details: ', error.details || error.message)
    throw error
  }
}

/**
 * Bulk enable discounts
 *
 * @param discountId[] An array of Discount IDs
 */
export const enableDiscounts = async (
  discountIds: string[]
): Promise<DiscountSuccess['successful']> => {
  try {
    const { data } = await apolloClient.mutate<
      DiscountSuccess,
      MutationEnableDiscountsArgs
    >({
      mutation: ENABLE_DISCOUNTS,
      variables: {
        discountIds,
      },
      /* Caching by apolloClient is using 'id' for creating unique reference which is causing issues in fulfillments array,
    so temporarily we are disabling cache for this query. Need to work on the right fix: https://www.apollographql.com/docs/react/caching/cache-configuration/ */
      fetchPolicy: 'no-cache',
    })
    return data?.successful
  } catch (e) {
    const data = JSON.parse(
      e instanceof Error ? e.message : 'Something went wrong'
    )
    const error = new DiscountError(data.details, data.message)
    console.error('Error Details: ', error.details || error.message)
    throw error
  }
}

/**
 * Bulk disable discounts
 *
 * @param discountId[] An array of Discount IDs
 */
export const disableDiscounts = async (
  discountIds: string[]
): Promise<DiscountSuccess['successful']> => {
  try {
    const { data } = await apolloClient.mutate<
      DiscountSuccess,
      MutationDisableDiscountsArgs
    >({
      mutation: DISABLE_DISCOUNTS,
      variables: {
        discountIds,
      },
      /* Caching by apolloClient is using 'id' for creating unique reference which is causing issues in fulfillments array,
    so temporarily we are disabling cache for this query. Need to work on the right fix: https://www.apollographql.com/docs/react/caching/cache-configuration/ */
      fetchPolicy: 'no-cache',
    })
    return data?.successful
  } catch (e) {
    const data = JSON.parse(
      e instanceof Error ? e.message : 'Something went wrong'
    )
    const error = new DiscountError(data.details, data.message)
    console.error('Error Details: ', error.details || error.message)
    throw error
  }
}

/**
 * Create discount
 *
 * @param input Discount form object
 */
export const createDiscount = async (
  input: DiscountFormInput
): Promise<DiscountResponse['discount']> => {
  try {
    const { data } = await apolloClient.mutate<
      DiscountResponse,
      MutationCreateDiscountArgs
    >({
      mutation: CREATE_DISCOUNT,
      variables: {
        input: sanitize(input),
      },
      /* Caching by apolloClient is using 'id' for creating unique reference which is causing issues in fulfillments array,
    so temporarily we are disabling cache for this query. Need to work on the right fix: https://www.apollographql.com/docs/react/caching/cache-configuration/ */
      fetchPolicy: 'no-cache',
    })
    return data?.discount
  } catch (e) {
    const data = JSON.parse(
      e instanceof Error ? e.message : 'Something went wrong'
    )
    const error = new DiscountError(data.details, data.message)
    console.error('Error Details: ', error.details || error.message)
    throw error
  }
}

/**
 * Update discount
 *
 * @param discountId Discount ID
 * @param input Discount form object
 */
export const updateDiscount = async (
  discountId: string,
  input: DiscountFormInput
): Promise<DiscountResponse['discount']> => {
  try {
    const { data } = await apolloClient.mutate<
      DiscountResponse,
      MutationUpdateDiscountArgs
    >({
      mutation: UPDATE_DISCOUNT,
      variables: {
        discountId,
        input: sanitize(input),
      },
      /* Caching by apolloClient is using 'id' for creating unique reference which is causing issues in fulfillments array,
      so temporarily we are disabling cache for this query. Need to work on the right fix: https://www.apollographql.com/docs/react/caching/cache-configuration/ */
      fetchPolicy: 'no-cache',
    })
    return data?.discount
  } catch (e) {
    const data = JSON.parse(
      e instanceof Error ? e.message : 'Something went wrong'
    )
    const error = new DiscountError(data.details, data.message)
    console.error('Error Details: ', error.details || error.message)
    throw error
  }
}

export const generateEmptyDiscount = (
  type: DiscountType
): DiscountFormInput => ({
  name: '',
  type,
  currency: CurrencyEnum.Usd,
  rules: {
    type: DiscountRuleType.Percentage,
    value: {
      value: 0,
    },
    prerequisiteMinPurchaseAmount: {
      amount: 0,
      currency: CurrencyEnum.Usd,
    },
    usageLimit: 0,
    prerequisiteCustomerIds: [],
    prerequisiteProductVariantIds: [],
    entitledProductVariantIds: [],
    entitledCountryCodes: [],
    prerequisiteToEntitlement: {
      prerequisiteQuantity: 0,
      prerequisitePurchaseAmount: {
        amount: 0,
        currency: CurrencyEnum.Usd,
      },
      entitledQuantity: 0,
      maxNumberOfUses: 0,
    },
    prerequisiteMinQuantity: 0,
    usageOncePerUser: false,
    startsAt: new Date().toISOString(),
  },
})

export const getSummaryDetails = (discount: Discount): string[] => {
  const summary: string[] = []
  if (discount.rules) {
    const {
      entitledCountryCodes,
      entitledProductVariantIds,
      prerequisiteCustomerIds,
      type,
      usageLimit,
      usageOncePerUser,
      value,
      prerequisiteMinQuantity,
      prerequisiteMinPurchaseAmount,
      prerequisiteToEntitlement,
    } = discount.rules

    // Products
    let entitledInventory = 'all Products'
    if (entitledProductVariantIds && entitledProductVariantIds.length > 0) {
      const productIds = new Set(
        entitledProductVariantIds.map((data) => data?.productId)
      )
      const variantIds = new Set(
        entitledProductVariantIds.map((data) => data?.variantId)
      )

      if (variantIds.size > 1) {
        const pluralForm = variantIds.size === 1 ? 'Variant' : 'Variants'
        entitledInventory = `${variantIds.size} ${pluralForm}`
      } else {
        const pluralForm = productIds.size === 1 ? 'Product' : 'Products'
        entitledInventory = `${productIds.size} ${pluralForm}`
      }
    }

    // Types
    if (value && type === 'fixedAmount' && 'amount' in value) {
      summary.push(`${formatPrice(value as Price)} off ${entitledInventory}`)
    } else if (value && type === 'percentage') {
      summary.push(
        `${(value as DiscountScalarValue).value}% off ${entitledInventory}`
      )
    } else if (type === 'freeShipping') {
      summary.push(`Free shipping on ${entitledInventory}`)
    } else if (type === 'buyXgetY') {
      const prerequisiteQuantity =
        prerequisiteToEntitlement?.prerequisiteQuantity || 0
      const prerequisitePurchaseAmount = prerequisiteToEntitlement?.prerequisitePurchaseAmount || {
        amount: 0,
        currency: CurrencyEnum.Usd,
      }
      const entitledQuantity = prerequisiteToEntitlement?.entitledQuantity || 0
      const maxNumberOfUses = prerequisiteToEntitlement?.maxNumberOfUses || 0
      const buy = prerequisiteQuantity
        ? prerequisiteQuantity
        : formatPrice(prerequisitePurchaseAmount)
      let desc = `Buy ${buy} items get ${entitledQuantity} items`
      if (maxNumberOfUses) {
        desc = `${desc} ${maxNumberOfUses} uses per order`
      }
      summary.push(desc)
    }

    // Customers
    if (usageLimit && usageLimit > 0) {
      summary.push(`Limit of ${usageLimit} uses`)
    }
    if (usageOncePerUser) {
      summary.push('One use per customer')
    }

    // Min prerequisite
    if (prerequisiteMinQuantity) {
      summary.push(`Minimum purchase of ${prerequisiteMinQuantity} items`)
    }
    if (prerequisiteMinPurchaseAmount) {
      summary.push(
        `Minimum purchase of ${formatPrice(
          prerequisiteMinPurchaseAmount
        )} items`
      )
    }

    // Countries
    if (entitledCountryCodes && entitledCountryCodes.length > 0) {
      const pluralForm =
        entitledCountryCodes.length === 1 ? 'country' : 'countries'
      summary.push(`For ${entitledCountryCodes.length} ${pluralForm}`)
    }

    // Customers
    if (prerequisiteCustomerIds && prerequisiteCustomerIds.length > 0) {
      const pluralForm =
        prerequisiteCustomerIds.length === 1 ? 'customer' : 'customers'
      summary.push(`For ${prerequisiteCustomerIds.length} ${pluralForm}`)
    }
  }

  return summary
}

export const getDiscountCurrencies = async (
  discountId: string
): Promise<DiscountCurrenciesResponse> => {
  const { data } = await apolloClient.query<
    { getDiscountCurrencies: DiscountCurrenciesResponse },
    QueryGetDiscountCurrenciesArgs
  >({
    query: GET_DISCOUNT_CURRENCIES,
    variables: {
      discountId,
    },
  })

  return data.getDiscountCurrencies
}

export const updateDiscountCurrencies = async (
  discountId: string,
  input: DiscountFormInput[]
): Promise<DiscountCurrenciesResponse | null | undefined> => {
  const { data } = await apolloClient.mutate<{
    updateDiscountCurrencies: DiscountCurrenciesResponse | null | undefined
  }>({
    mutation: UPDATE_DISCOUNT_CURRENCIES,
    variables: {
      discountId,
      input,
    },
  })

  return data?.updateDiscountCurrencies
}

export const syncDiscountCurrencies = async (
  discountId: string
): Promise<Discount[] | null | undefined> => {
  const { data } = await apolloClient.mutate<{
    syncDiscountCurrencies: Discount[] | null | undefined
  }>({
    mutation: SYNC_DISCOUNT_CURRENCIES,
    variables: {
      discountId,
    },
  })

  return data?.syncDiscountCurrencies
}

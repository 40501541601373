


























import Vue, { PropType } from 'vue'
import { DiscountFormInput, Maybe } from '../../../../shared/types/types'

export default Vue.extend({
  name: 'DiscountFormUsageLimit',
  props: {
    form: {
      type: Object as PropType<DiscountFormInput>,
      required: true,
    },
  },
  data() {
    return {
      usageLimit: null as Maybe<string>,
      enableUsageLimit: false,
      usageOncePerUser: false,
    }
  },
  computed: {
    discount(): DiscountFormInput {
      return this.form
    },
  },
  watch: {
    enableUsageLimit() {
      this.discount.rules.usageLimit = this.enableUsageLimit
        ? parseInt(this.usageLimit || '0')
        : null
      this.usageLimit = (this.discount.rules?.usageLimit || 0).toString()
    },
    usageLimit() {
      this.discount.rules.usageLimit = this.usageLimit
        ? parseInt(this.usageLimit)
        : null
    },
    usageOncePerUser() {
      this.discount.rules.usageOncePerUser = this.usageOncePerUser
    },
  },
  created() {
    const usageLimit = this.discount.rules?.usageLimit || 0
    this.usageLimit = usageLimit.toString()
    this.enableUsageLimit = usageLimit !== 0
    this.usageOncePerUser = this.discount.rules.usageOncePerUser || false
  },
})
